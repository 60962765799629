
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const DNAME = process.env.REACT_APP_DNAME;

// function HeadingGames() {
//   const [games, setGames] = useState([]);
//   const [filteredGames, setFilteredGames] = useState([]);
//   const [todayResults, setTodayResults] = useState([]);
//   const [yesterdayResults, setYesterdayResults] = useState([]);

//   useEffect(() => {
//     getGames();
//     getGameResults();
//   }, []);

//   useEffect(() => {
//     filterGames();
//     const interval = setInterval(() => {
//       filterGames(); // Update games every minute to reflect time changes
//     }, 60000);
//     return () => clearInterval(interval);
//   }, [games, todayResults, yesterdayResults]);

//   const getGames = async () => {
//     try {
//       const result = await axios.get(`${DNAME}/getgames`);
//       setGames(result.data || []);
//     } catch (error) {
//       console.error('Error fetching games:', error);
//       toast.error("Error fetching games");
//     }
//   };

//   const getGameResults = async () => {
//     try {
//       const today = new Date();
//       const yesterday = new Date(today);
//       yesterday.setDate(today.getDate() - 1);

//       const [todayResult, yesterdayResult] = await Promise.all([
//         axios.get(`${DNAME}/getgameresults`, { params: { date: formatDate(today) } }),
//         axios.get(`${DNAME}/getgameresults`, { params: { date: formatDate(yesterday) } })
//       ]);

//       setTodayResults(todayResult.data || []);
//       setYesterdayResults(yesterdayResult.data || []);
//     } catch (error) {
//       console.error('Error fetching game results:', error);
//       toast.error('Error fetching game results');
//     }
//   };

//   const formatDate = (date) => {
//     const offset = 5.5 * 60; // IST is UTC+5:30
//     const localDate = new Date(date.getTime() + offset * 60000);
//     return localDate.toISOString().split('T')[0];
//   };

//   const filterGames = () => {
//     const now = new Date();
//     const currentMinutesTotal = now.getHours() * 60 + now.getMinutes();

//     // Parse games with their result times in 24-hour format
//     const parsedGames = games
//       .filter(game => game?.resultTime)
//       .map(game => {
//         const [gameHours, gameMinutes] = game.resultTime.split(':').map(Number);
//         const gameMinutesTotal = gameHours * 60 + gameMinutes;
//         return { ...game, gameMinutesTotal };
//       });

//     // Sort games based on resultTime
//     const sortedGames = parsedGames.sort((a, b) => a.gameMinutesTotal - b.gameMinutesTotal);

//     // Get the just-passed game and the next upcoming game
//     let justPassedGame = null;
//     let nextUpcomingGame = null;

//     for (let i = 0; i < sortedGames.length; i++) {
//       if (sortedGames[i].gameMinutesTotal <= currentMinutesTotal) {
//         justPassedGame = sortedGames[i]; // Most recent past game
//       } else if (!nextUpcomingGame) {
//         nextUpcomingGame = sortedGames[i]; // First upcoming game
//       }
//     }

//     const filtered = [justPassedGame, nextUpcomingGame].filter(Boolean);

//     // Map the filtered games with results
//     const mergedGames = filtered.map(game => {
//       const todayResult = todayResults.find(result => result.gameId === game._id);
//       const yesterdayResult = yesterdayResults.find(result => result.gameId === game._id);

//       return {
//         ...game,
//         todayResult: todayResult ? todayResult.result : <img className='h-10' src={require("./d.gif")} alt="Loading" />,
//         yesterdayResult: yesterdayResult ? yesterdayResult.result : <img className='h-10' src={require("./d.gif")} alt="Loading" />
//       };
//     });

//     setFilteredGames(mergedGames);
//   };

//   console.log("Filtered Games ===>", filteredGames);

//   return (
//     <>
//       {filteredGames.length > 0 ? (
//         filteredGames.map((game, idx) => (
//           <div key={idx} className='flex justify-center items-center flex-col bg-black h-32'>
//             <div className='font-bold uppercase text-white text-4xl'>{game.name}</div>
//             <div className='flex justify-between items-center w-40'>
//               <span className='font-bold text-white text-3xl'>{game.yesterdayResult}</span>
//               <span className='text-red-600'><img src={require("./teer.gif")} className="h-6" alt="indicator" /></span>
//               <span className='font-bold text-white text-3xl'>{game.todayResult}</span>
//             </div>
//           </div>
//         ))
//       ) : (
//         <div className='flex justify-center items-center flex-col bg-black h-32 text-white'>
//           <span>No games to display</span>
//         </div>
//       )}
//       <ToastContainer />
//     </>
//   );
// }

// export default HeadingGames;



















import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DNAME = process.env.REACT_APP_DNAME;

function HeadingGames() {
  const [games, setGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [todayResults, setTodayResults] = useState([]);
  const [yesterdayResults, setYesterdayResults] = useState([]);
  const [dayBeforeYesterdayResults, setDayBeforeYesterdayResults] = useState([]);

  useEffect(() => {
    getGames();
    getGameResults();
  }, []);

  useEffect(() => {
    filterGames();
    const interval = setInterval(() => {
      filterGames(); // Update games every minute to reflect time changes
    }, 60000);
    return () => clearInterval(interval);
  }, [games, todayResults, yesterdayResults, dayBeforeYesterdayResults]);

  const getGames = async () => {
    try {
      const result = await axios.get(`${DNAME}/getgames`);
      setGames(result.data || []);
    } catch (error) {
      console.error('Error fetching games:', error);
      toast.error("Error fetching games");
    }
  };

  const getGameResults = async () => {
    try {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      const dayBeforeYesterday = new Date(today);
      dayBeforeYesterday.setDate(today.getDate() - 2);

      const [todayResult, yesterdayResult, dayBeforeYesterdayResult] = await Promise.all([
        axios.get(`${DNAME}/getgameresults`, { params: { date: formatDate(today) } }),
        axios.get(`${DNAME}/getgameresults`, { params: { date: formatDate(yesterday) } }),
        axios.get(`${DNAME}/getgameresults`, { params: { date: formatDate(dayBeforeYesterday) } })
      ]);

      setTodayResults(todayResult.data || []);
      setYesterdayResults(yesterdayResult.data || []);
      setDayBeforeYesterdayResults(dayBeforeYesterdayResult.data || []);
    } catch (error) {
      console.error('Error fetching game results:', error);
      toast.error('Error fetching game results');
    }
  };

  const formatDate = (date) => {
    const offset = 5.5 * 60; // IST is UTC+5:30
    const localDate = new Date(date.getTime() + offset * 60000);
    return localDate.toISOString().split('T')[0];
  };

  const filterGames = () => {
    const now = new Date();
    const currentMinutesTotal = now.getHours() * 60 + now.getMinutes();

    // Parse games with their result times in 24-hour format
    const parsedGames = games
      .filter(game => game?.resultTime)
      .map(game => {
        const [gameHours, gameMinutes] = game.resultTime.split(':').map(Number);
        const gameMinutesTotal = gameHours * 60 + gameMinutes;
        return { ...game, gameMinutesTotal };
      });

    // Sort games based on resultTime
    const sortedGames = parsedGames.sort((a, b) => a.gameMinutesTotal - b.gameMinutesTotal);

    // Get the just-passed game and the next upcoming game
    let justPassedGame = null;
    let nextUpcomingGame = null;

    for (let i = 0; i < sortedGames.length; i++) {
      if (sortedGames[i].gameMinutesTotal <= currentMinutesTotal) {
        justPassedGame = sortedGames[i]; // Most recent past game
      } else if (!nextUpcomingGame) {
        nextUpcomingGame = sortedGames[i]; // First upcoming game
      }
    }

    const filtered = [justPassedGame, nextUpcomingGame].filter(Boolean);

    // Map the filtered games with results
    const mergedGames = filtered.map(game => {
      if (game.name.toLowerCase() === "gali") {
        // For 'gali', use yesterday's and day-before-yesterday's results
        const yesterdayResult = yesterdayResults.find(result => result.gameId === game._id);
        const dayBeforeYesterdayResult = dayBeforeYesterdayResults.find(result => result.gameId === game._id);

        return {
          ...game,
          todayResult: yesterdayResult ? yesterdayResult.result : <img className='h-10' src={require("./d.gif")} alt="Loading" />,
          yesterdayResult: dayBeforeYesterdayResult ? dayBeforeYesterdayResult.result : <img className='h-10' src={require("./d.gif")} alt="Loading" />
        };
      } else {
        // For other games, use today and yesterday's results
        const todayResult = todayResults.find(result => result.gameId === game._id);
        const yesterdayResult = yesterdayResults.find(result => result.gameId === game._id);

        return {
          ...game,
          todayResult: todayResult ? todayResult.result : <img className='h-10' src={require("./d.gif")} alt="Loading" />,
          yesterdayResult: yesterdayResult ? yesterdayResult.result : <img className='h-10' src={require("./d.gif")} alt="Loading" />
        };
      }
    });

    setFilteredGames(mergedGames);
  };

  console.log("Filtered Games ===>", filteredGames);

  return (
    <>
      {filteredGames.length > 0 ? (
        filteredGames.map((game, idx) => (
          <div key={idx} className='flex justify-center items-center flex-col bg-black h-32'>
            <div className='font-bold uppercase text-white text-4xl'>{game.name}</div>
            <div className='flex justify-between items-center w-40'>
              <span className='font-bold text-white text-3xl'>{game.yesterdayResult}</span>
              <span className='text-red-600'><img src={require("./teer.gif")} className="h-6" alt="indicator" /></span>
              <span className='font-bold text-white text-3xl'>{game.todayResult}</span>
            </div>
          </div>
        ))
      ) : (
        <div className='flex justify-center items-center flex-col bg-black h-32 text-white'>
          <span>No games to display</span>
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default HeadingGames;
