import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const DNAME = process.env.REACT_APP_DNAME;

function ResultTable() {
  const [gameName, setGameName] = useState([]);
  const [yesterdayResults, setYesterdayResults] = useState([]);
  const [todayResults, setTodayResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getGames();
    getGameResults();
  }, []);

  const getGames = async () => {
    try {
      const result = await axios.get(`${DNAME}/getgames`);
      if (result && result.data) {
        const sortedGames = result.data.sort((a, b) => a.showNumber - b.showNumber);
        setGameName(sortedGames);
      } else {
        setGameName([]);
      }
    } catch (error) {
      console.error('Error fetching games:', error);
      toast.error("Error fetching games");
    }
  };

  // const getGameResults = async () => {
  //   try {
  //     // Get current date and time in IST
  //     const todayDate = new Date();
  //     const todayStartIST = new Date(todayDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
  //     todayStartIST.setHours(0, 0, 0, 0); // Start of today in IST
  
  //     // Determine if the current time is past midnight in IST
  //     const currentIST = new Date(todayDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
  //     const isPastMidnight = currentIST.getHours() >= 0 && currentIST.getHours() < 24 && currentIST.getMinutes() < 30;
  
  //     // Calculate dates based on whether it's past midnight
  //     const dateForToday = isPastMidnight ? new Date(todayStartIST.getTime() - 24 * 60 * 60 * 1000) : todayStartIST;
  //     const todayDateStr = dateForToday.toISOString(); // Convert to UTC
  //     const yesterdayDateStr = new Date(dateForToday.getTime() - 24 * 60 * 60 * 1000).toISOString(); // Convert to UTC
      
  //     const currentDate = new Date();
      
  //     console.log("ddddddddaaaaaattttteeee====>>",currentDate);
      
  //     // Fetch results
  //     const [todayResult, yesterdayResult] = await Promise.all([
  //       axios.get(`${DNAME}/getgameresults`, { params: { date: todayDateStr } }),
  //       axios.get(`${DNAME}/getgameresults`, { params: { date: yesterdayDateStr } })
  //     ]);
  
  //     if (todayResult && todayResult.data) {
  //       setTodayResults(todayResult.data);
  //     }
  //     if (yesterdayResult && yesterdayResult.data) {
  //       setYesterdayResults(yesterdayResult.data);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching game results:', error);
  //     toast.error('Error fetching game results');
  //   }
  // };
  
  const getGameResults = async () => {
    try {
      // Helper function to format date as YYYY-MM-DD
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
  
      // Get current date and yesterday's date
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
  
      const todayDateStr = formatDate(today);
      const yesterdayDateStr = formatDate(yesterday);
  
      // Fetch results
      const [todayResult, yesterdayResult] = await Promise.all([
        axios.get(`${DNAME}/getgameresults`, { params: { date: todayDateStr } }),
        axios.get(`${DNAME}/getgameresults`, { params: { date: yesterdayDateStr } })
      ]);
  
      // Handle results
      if (todayResult && todayResult.data) {
        setTodayResults(todayResult.data);
      }
      if (yesterdayResult && yesterdayResult.data) {
        setYesterdayResults(yesterdayResult.data);
      }
    } catch (error) {
      console.error('Error fetching game results:', error);
      toast.error('Error fetching game results');
    }
  };
  
  
  const formatTime = (time) => {
    let [hour, minute] = time.split(':').map(Number);
    // minute += 20;
    if (minute >= 60) {
      hour += 1;
      minute -= 60;
    }
    let ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12; // Convert hour to 12-hour format
    minute = minute.toString().padStart(2, '0'); // Ensure minute is always two digits
    return `${hour}:${minute} ${ampm}`;
  };

  const getResult = (results, gameId) => {
    const result = results.find(result => result.gameId === gameId);
    return result ? result.result : null;
  };

  return (
    <div className=''>
      <table className='w-full overflow-x-auto'>
        <thead>
          <tr className='bg-black text-white text-center'>
            <th className='w-1/3 border border-black'>सट्टा का नाम</th>
            <th className='w-1/3 border border-black'>कल आया था</th>
            <th className='w-1/3 border border-black'>आज का रिज़ल्ट</th>
          </tr>
        </thead>
        <tbody>
          {gameName.map((item, idx) => (
            <tr className='text-center h-16 snap-start' key={idx}>
              <td className='w-1/3 uppercase border border-black bg-yellow-400'>
                <div onClick={() => navigate('/custommonthlychart')} className='text-xl font-bold hover:text-blue-600 hover:cursor-pointer'>
                  {item.name}
                </div>
                <div>{formatTime(item.resultShowTime)}</div>
              </td>
              <td className='w-1/3 border border-black'>
                <div className='text-xl font-bold'>
                  {getResult(yesterdayResults, item._id) || '-'}
                </div>
              </td>
              <td className=' border border-black'>
                <div className='flex justify-center items-center h-full'>
                  <div className='text-xl font-bold'>
                    {getResult(todayResults, item._id) || <img className='h-10' src={require("./d.gif")} alt="Loading" />}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
}

export default ResultTable;
